.blog-card {
    clip-path: polygon(100% 0, 100% 75%, 50% 100%, 0 75%, 0 0, 0% 0%);
    background-color: black;
    padding: 100px 0 50px 0;
}

    .blog-card h1 {
        text-align: center;
        font-weight: 900;
        font-size: 55px;
        color: #fff;
    }
/* =====================================junk-removal-info-section===================================== */
.junk-removal-info-section {
    display: flex;
    flex-direction: row;
    padding: 40px 0;
}

.free-junk-info-div {
    width: 100%;
    padding-right: 20px;
}

.junk-removal-info-section h1 {
    font-family: sage headline, helvetica neue, Roboto, Segoe UI, Arial;
    font-size: 55px;
    font-weight: 900;
}

.junk-removal-info-section p {
    font-family: sage headline, helvetica neue, Roboto, Segoe UI, Arial;
    font-size: 22px;
    font-weight: 400;
}
/* .junk-boxes-card-content-img{
    width: 50%;
} */
.junk-removal-info-img img {
    width: 100%;
    height: 100%;
}

.junk-removing-img {
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    position: sticky;
    background-size: cover;
}


.blog-paragraph {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    padding: 50px;
}
/* ======================================junk-boxes=============================================== */
.junk-boxes {
    background-color: #000;
    background-image: url(./images/green-wave.svg);
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    padding: 100px 0;
}

.junk-boxes-card {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 20px;
}

.junk-boxes-card-content {
    background: #F2F5F6;
    border-radius: 20px;
    padding: 20px;
}

    .junk-boxes-card-content h3 {
        text-align: center;
        font-size: 25px;
        font-weight: 800;
        padding: 10px;
    }

    .junk-boxes-card-content p {
        text-align: center;
        font-size: 16px;
    }

.junk-boxes-btn {
    padding: 8px 12px;
    border-radius: 20px;
    background: #000;
    color: #fff;
    border: 2px solid #fff;
}

    .junk-boxes-btn:hover {
        background-color: #343333;
    }

.junk-boxes-card-content-img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}
/* ========================================================================================== */
.contect-num {
    text-align: center;
    font-size: 45px;
    font-weight: 800;
    color: #000;
}

.call-us-section {
    background: #fff;
    padding: 70px;
    margin-top: 30px;
}

.number-design {
    color: #00D639;
    margin-left: 10px;
}

.Service-design {
    text-align: center;
    color: #000;
    font-size: 20px;
}

.number {
    text-decoration: none;
    color: #00D639;
    cursor: pointer;
}

.number-btn {
    text-decoration: none;
    color: #00D639;
    cursor: pointer;
}

    .number-btn i {
        margin-right: 3px;
    }

    .number-btn:hover {
        color: #fff;
    }




/* ============================================== Dl design ===================================== */

.dl-blurbs {
    display: grid;
}

@media (min-width: 768px) {
    .dl-blurbs {
        grid-template-columns: repeat(2, 1fr);
        gap: 2vw;
    }
}

dl {
    counter-reset: count;
    background: linear-gradient(90deg, #282828 20%, rgba(0, 0, 0, 0) 0%);
    padding-left: 4vw;
    padding-right: 4vw;
    margin: 0;
}

@media (max-width: 768px) {
    dl {
        background: linear-gradient(90deg, #282828 10%, rgba(0, 0, 0, 0) 0%);
        padding-left: 2vw;
    }
}

@media (min-width: 1024px) {
    dl {
        padding-top: 3vw;
        padding-bottom: 3vw;
    }
}

dl + dl {
    counter-reset: counter 4;
}

dt {
    counter-increment: count;
    color: #00D639;
    font-family: 'Montserrat', sans-serif;
    font-size: 3vw;
    text-transform: uppercase;
}

    dt::before {
        content: counter(count,decimal-leading-zero) ".";
        font-family: 'Abril Fatface', cursive;
        color: #5ea5b8 ff;
        margin-left: -1vw;
        padding-right: 32px;
    }

dd {
    margin-left: 10vw;
    margin-bottom: 2vh;
}

@media only screen and (max-width: 650px) {
    .blog-card {
        clip-path: polygon(100% 0, 100% 85%, 50% 100%, 0 85%, 0 0, 0% 0%);
        padding: 50px 0 50px 0;
    }

        .blog-card h1 {
            font-size: 35px;
        }

    .call-us-section {
        padding: 10px;
        margin-top: 30px;
    }

    .contect-num {
        text-align: center;
        font-size: 35px;
        font-weight: 800;
        color: #000;
    }
    /* =====================================junk-removal-info-section===================================== */
    .junk-removal-info-section {
        display: flex;
        flex-direction: column;
        padding: 20px 20px;
    }

    .blog-card {
        clip-path: polygon(100% 0, 100% 85%, 50% 100%, 0 85%, 0 0, 0% 0%);
        padding: 50px 0 50px 0;
    }

    .free-junk-info-div {
        width: 100%;
        padding-right: 0px;
    }

    .junk-removal-info-section h1 {
        font-family: sage headline, helvetica neue, Roboto, Segoe UI, Arial;
        font-size: 45px;
        font-weight: 900;
    }

    .junk-removal-info-section p {
        font-family: sage headline, helvetica neue, Roboto, Segoe UI, Arial;
        font-size: 20px;
        font-weight: 400;
    }

    .junk-removal-info-img img {
        width: 100%;
        height: 100%;
    }

    .junk-removing-img {
        border-top-left-radius: 40px;
        border-bottom-left-radius: 40px;
        border-top-right-radius: 40px;
        border-bottom-right-radius: 40px;
        position: sticky;
        background-size: cover;
    }
}
