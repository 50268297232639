
.Contact-section-boxes{
    background-color:black;
    padding: 50px;
  }
  .junkremoval-heading{
    font-size:40px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 900;
    text-align: center;
    padding-top: 20px;
    color: #fff;
  }
  .free-trial-card{
    display: flex;
    flex-wrap: wrap;
    gap: 60px;
  
  }
  .content-flex{
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  .content-flex i{
    color: #00D639;
    font-size: 30px;
  }
  .content-flex h4{
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    font-family: sage headline, helvetica neue, Roboto, Segoe UI, Arial;
  }
  .content-flex p{
    color: #fff;
    font-size: 16px;
    font-family: sage headline, helvetica neue, Roboto, Segoe UI, Arial;
  }
  .free-junk-btn{
    padding: 10px 12px;
    text-decoration:none;
    border-radius: 20px;
    background: #000;
    color: #fff;
    border: 2px solid #fff;
  }
    .free-junk-btn:hover {
        background-color: #1f1f1f;
        color: #fff;
    }
  .number-btn {
    text-decoration: none;
    color: #00D639;
    cursor: pointer;
    padding: 10px 12px;
    border-radius: 20px;
    background: #000;
    border: 2px solid #fff;
  }
  
    .number-btn i {
        margin-right: 3px;
    }
  
    .number-btn:hover {
        color: #fff;
    }
  /* =====================================junk-removal-info-section===================================== */
  .junk-removal-info-section{
    display: flex;
    flex-direction: row;
    padding: 40px 0;
  }
  .free-junk-info-div{
    width: 100%;
    padding-right: 20px;
  }
  .junk-removal-info-section h1{
    font-family: sage headline, helvetica neue, Roboto, Segoe UI, Arial;
    font-size: 55px;
    font-weight: 900;
  }
  .junk-removal-info-section p{
    font-family: sage headline, helvetica neue, Roboto, Segoe UI, Arial;
    font-size: 22px;
    font-weight: 400;
  }
  /* .junk-boxes-card-content-img{
    width: 50%;
  } */
  .junk-removal-info-img img{
  width: 100%;
  height: 100%;
  }
  .junk-removing-img{
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    position: sticky;
    background-size: cover;
  }
  /* ======================================junk-boxes=============================================== */
  .junk-boxes{
    background-color: #000;
    background-image: url(./images/green-wave.svg);
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    padding: 100px 0;
  }
  .junk-boxes-card {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); 
    grid-gap: 20px; 
  }
  
  .junk-boxes-card-content {
    background: #F2F5F6;
    border-radius: 20px;
    padding: 20px; 
  }
  .junk-boxes-card-content h3{
    text-align: center;
    font-size: 25px;
    font-weight: 800;
    padding: 10px;
  }
  .junk-boxes-card-content p{
    text-align: center;
    font-size: 16px;
  }
  .junk-boxes-btn{
    padding: 8px 12px;
    border-radius: 20px;
    background: #000;
    color: #fff;
    border: 2px solid #fff;
    
  }
  .junk-boxes-btn:hover{
    background-color: #343333;
  }
  .junk-boxes-card-content-img {
    width: 100%; 
    height: auto; 
    border-radius: 10px; 
  }
  /* ===================================================Accordians============================================= */
  .Accordian-section {
    background-color:#fff;
     font-family:'Arial';
     padding:4em 4em;
   }
   .Accordian-section h1{
     color:#000;
     text-align:center;
   }
   /*--------Accordion-------*/
   .acc-kontainer {
     width: 100%;
     margin: auto;
   }
   .acc-kontainer .acc-body {
     width: 98%;
     width: calc(100% - 20px);
     margin: 0 auto;
     height: 0;
     color: rgba(0, 0, 0, 0);;
     background-color: rgba(255, 255, 255, 0.2);
     line-height: 28px;
     padding: 0 20px;
     box-sizing: border-box;
     transition: 0.5s;
   }
   
   .acc-kontainer label {
     cursor: pointer;
     background-color: rgba(38, 146, 6, 0.1);
     border-left: 5px solid #00D639;
     border-bottom: 1px solid rgba(255, 255, 255, 0.1);
     display: block;
     padding: 15px;
     width: 100%;
     color: #000;
     font-weight: 400;
     box-sizing: border-box;
     z-index: 100;
   }
   
   .acc-kontainer input{
     display: none;
   }
   
   .acc-kontainer label:before {
     font-family: 'FontAwesome';
     content: '\f067';
     font-weight: bolder;
     float: right;
   }
   
   .acc-kontainer input:checked+label {
     background-color: #00D639;
   }
   
   .acc-kontainer input:checked+label:before {
     font-family: 'FontAwesome';
     content: '\f00d';
     transition: 0.5s;
   }
   
   .acc-kontainer input:checked~.acc-body {
     height: auto;
     color: #000;
     background-color: rgba(176, 142, 70, 0.1);
     font-size: 16px;
     padding: 20px;
     transition: 0.5s;
   }
  
   /* =============================================whatsapp icons==================================== */
  
   *{
    margin:0;
    padding:0;
  }
  
  body{
    position:relative;
  }
  
  
  .whatsapp-wrapper{ 
    position:fixed;
    bottom:20px;
    right:20px;
    z-index:1000;
  }
  
  .whatsapp-wrapper a:nth-child(1){
    background-color:green;
  }
  
  .whatsapp-wrapper a:nth-child(2){
    background-color:blue;
  }
    .whatsapp-wrapper a:nth-child(3) {
        background-color: #1f1f79;
    }
    .whatsapp-wrapper a:nth-child(4) {
        background: #f09433;
        background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
        background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
        background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
    }
  .whatsapp-wrapper a:nth-child(5){
    
    animation: 0.7s ease-out 0s 1 FadeIn;
    transition:all 0.3s;
  }
  
  
  .whatsapp-wrapper a:hover:nth-child(5){
    
    background-color:#ffc800;
  }
  
  
  @keyframes FadeIn {
    0% {
     transform:rotate(0deg);
    }
    100% {
      transform:rotate(360deg); 
    }
  }
  
  
  .hide{
    position:absolute;
    bottom:0px !important;
    z-index:-1;
    transition:all 2s;
    
  }
  
  .social i{
   font-size: 30px; 
  }
  .close-button i {
    font-size: 27px;
  }
  .whatsapp-wrapper a {
    height: 50px;
    width: 50px;
    background: #006fa0;
    padding: 2px;
    display: flex;
    flex-direction: row;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #fff;
    margin: 2px;
  }
  @media only screen and (max-width: 650px) {
  /* =====================================junk-removal-info-section===================================== */
  .junk-removal-info-section{
    display: flex;
    flex-direction: column;
    padding: 20px 20px;
  }
  .free-junk-info-div{
    width: 100%;
    padding-right: 0px;
  }
  .junk-removal-info-section h1{
    font-family: sage headline, helvetica neue, Roboto, Segoe UI, Arial;
    font-size: 45px;
    font-weight: 900;
  }
  .junk-removal-info-section p{
    font-family: sage headline, helvetica neue, Roboto, Segoe UI, Arial;
    font-size: 20px;
    font-weight: 400;
  }
  
  .junk-removal-info-img img{
  width: 100%;
  height: 100%;
  }
  .junk-removing-img{
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    position: sticky;
    background-size: cover;
  }
  .Accordian-section {
    background-color:#fff;
     font-family:'Arial';
     padding:2em 2em;
   }
  }
  
  
  .underline {
    position: relative;
    text-decoration: none; 
    color: #000; 
    font-weight:800;
  }
    .underline:hover {
        color: #00D639;
    }
  
    .underline::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 4px;
        background-color: #00D639;
        color: #00D639;
        bottom: -2px;
        left: 0;
        transition: width 0.3s ease;
    }
  
    /* Hover effect */
    .underline:hover::after {
        width: 0;
       
    }