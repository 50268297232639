.contact-us-card {
    clip-path: polygon(100% 0, 100% 75%, 50% 100%, 0 75%, 0 0, 0% 0%);
    background-color: black;
    padding: 100px 0 50px 0;
}

    .contact-us-card h1 {
        text-align: center;
        font-weight: 900;
        font-size: 55px;
        color: #fff;
    }

.quick-msg-design-h2 {
    color: #000;
    font-weight: 800;
    text-align: center;
    padding-top: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.quick-msg-design-p {
    text-align: center;
    padding-bottom: 20px;
    font-size: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.contact-us-modal {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.first-card {
    border-radius: 20px;
    background-color: #b6e4c3;
    padding: 20px;
    width: 50%;
}

.form-card {
    background-color: #000;
    width: 40%;
    padding: 20px;
    border-radius: 20px;
}

input {
    border: 2px solid #00D639;
    outline: 2px solid #000;
    border-radius: 5px;
    height:40px;
    width: 100%;
}

label {
    font-size: 20px;
    font-weight: 500;
    margin: 5px 0;
    color: #fff;
}

textarea {
    border: 2px solid #00D639;
    outline: 2px solid #000;
    border-radius: 5px;
    height: 190px;
    width: 100%;
}

.form-btn-container {
    margin: 5px 0;
    text-align: center;
}

.submit-btn {
    border-radius: 20px;
    padding: 6px 12px;
    font-weight: 400;
    background-color: #00D639;
}

.contect-num {
    text-align: center;
    font-size: 45px;
    font-weight: 800;
    color: #000;
}

.call-us-section {
    background: #fff;
    padding: 80px;
    margin-top: 30px;
}

.number-design {
    color: #00D639;
}

.Service-design {
    text-align: center;
    color: #000;
    font-size: 20px;
}

.icon-ds {
    color: #00D639;
    margin-right: 10px;
}
iframe{
    width:100%;
}
.text-design {
    font-size: 20px;
    font-weight: 700;
}

.number-ds {
    margin-left: 100px;
    text-decoration: none;
    color: #000;
    cursor: pointer;
}

.email-design {
    margin-left: 100px;
    text-decoration: none;
    color: #000;
    cursor: pointer;
}

/* Media Query For different screens */
@media (min-width:220px) and (max-width:479px) { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
    .contact-us-card {
        padding: 50px 0 50px 0;
    }

        .contact-us-card h1 {
            font-size: 35px;
        }

    .quick-msg-design-h2 {
        padding-top: 18px;
    }

    .contact-us-modal {
        display: flex;
        flex-direction: column-reverse;
        gap: 20px;
    }

    .first-card {
        width: 100%;
    }

    .form-card {
        width: 100%;
    }

    .number-ds {
        margin-left: 20px;
    }

    .email-design {
        margin-left: 20px;
    }

    .contect-num {
        font-size: 35px;
    }

    .call-us-section {
        padding: 40px;
        margin-top: 30px;
    }
}

@media (min-width:480px) and (max-width:599px) { /* smartphones, Android phones, landscape iPhone */
    .contact-us-card {
        padding: 50px 0 50px 0;
    }

        .contact-us-card h1 {
            font-size: 35px;
        }

    .quick-msg-design-h2 {
        padding-top: 18px;
    }

    .contact-us-modal {
        display: flex;
        flex-direction: column-reverse;
        gap: 20px;
    }

    .first-card {
        width: 100%;
    }

    .form-card {
        width: 100%;
    }

    .number-ds {
        margin-left: 20px;
    }

    .email-design {
        margin-left: 20px;
    }

    .contect-num {
        font-size: 35px;
    }

    .call-us-section {
        padding: 40px;
        margin-top: 30px;
    }
}

@media (min-width:600px) and (max-width: 800px) { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    .contact-us-card {
        padding: 50px 0 50px 0;
    }

        .contact-us-card h1 {
            font-size: 35px;
        }

    .quick-msg-design-h2 {
        padding-top: 18px;
    }

    .contact-us-modal {
        display: flex;
        flex-direction: column-reverse;
        gap: 20px;
    }

    .first-card {
        width: 100%;
    }

    .form-card {
        width: 100%;
    }

    .number-ds {
        margin-left: 20px;
    }

    .email-design {
        margin-left: 20px;
    }

    .contect-num {
        font-size: 35px;
    }

    .call-us-section {
        padding: 40px;
        margin-top: 30px;
    }
}
