

* {
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
  font-family: "Poppins", sans-serif;
}
/* #Navigation
================================================== */
.nav-experience-card {
  display: flex;
  flex-direction: row;
  /*justify-content:space-around;*/
  background-color: #e5e4e6;
  height: 50px;
  align-items: center;
}

.nav-experience-card h6 {
  font-size: 13px;
  font-weight: 700;
  color: #1ea1bc;
  line-height: 5px;
  align-items: center;
  text-align: center;
}
.header-contact-details-card {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.header-contact-detail {
  justify-content: center;
  align-items: center;
  line-height: 15px;
}
.det-li a {
  font-size: 13px;
  color: #1ea1bc;
  
}
.mobile-icon a {
  font-size: 30px;
  color: #1ea1bc;
}
.book-me {
  display:flex;
  justify-content: center;
  align-items: center;
}
  .book-me a {
      /*display: inline-block;*/
      padding: 10px 20px;
      border-radius: 25px;
      color: #fff;
      background: #004658;
      text-decoration: none;
      /*margin-top: 25px;*/
      transition: all 0.3s ease;
  }

  .book-me a:hover {
      color: #fff;
      background-color: #c87e4f;
  }
  .start-header {
  opacity: 1;
  transform: translateY(0);
  padding: 15px 0;
  box-shadow: 0 10px 30px 0 rgba(138, 155, 165, 0.15);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

  .start-header.scroll-on {
      box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
      padding: 10px 0;
      -webkit-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out;
  }

      .start-header.scroll-on .navbar-brand img {
          height: 24px;
          -webkit-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out;
      }
/* #1ea1bc,#020024 */
.navigation-wrap {
  background: #000;
  position:relative;
  width: 100%;
  top: 0px;
  left: 0;
  z-index: 1000;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.navbar {
  padding: 0;
}

.navbar-brand img {
  height: 68px;
  width: auto;
  /* display: block; */
  filter: brightness(100%);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.navbar-togler {
  display:none;
  float: right;
  border: 2px solid #fff;
  border-radius:10px;
  background:none;
  padding: 6px 12px;
}
  .navbar-togler i{
      font-size:25px;
      color:#fff;

  }
  .navbar-togler:active,
  .navbar-togler:focus {
      outline: none;
  }

.navbar-light .navbar-togler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 1px solid #fff;
  transition: all 300ms linear;
  color:#fff;
}

  .navbar-light .navbar-togler-icon:after,
  .navbar-light .navbar-togler-icon:before {
      width: 44px;
      position: absolute;
      height: 1px;
      background-color: #fff;
      top: 0;
      left: 0;
      content: '';
      z-index: 2;
      transition: all 300ms linear;
  }

  .navbar-light .navbar-togler-icon:after {
      top: 8px;
  }

.navbar-togler[aria-expanded="true"] .navbar-togler-icon:after {
  transform: rotate(45deg);
}

.navbar-togler[aria-expanded="true"] .navbar-togler-icon:before {
  transform: translateY(8px) rotate(-45deg);
}

.navbar-togler[aria-expanded="true"] .navbar-togler-icon {
  border-color: transparent;
}

.nav-link {
  color: #fff !important;
  font-weight: 500;
  transition: all 200ms linear;
}

.navbar-nav {
  display: flex;
  gap: 50px;
}

.nav-item:hover .nav-link {
  color: #00D639 !important;
}

.nav-item.active .nav-link {
  color: #777 !important;
}

.nav-link {
  position: relative;
  padding: 0px 0 !important;
  display: inline-block;
}

.nav-item:after {
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  content: '';
  background-color: #00D639;
  opacity: 0;
  transition: all 200ms linear;
}

.nav-item:hover:after {
  bottom: 0;
  opacity: 1;
}

.nav-item.active:hover:after {
  opacity: 0;
}

.nav-item {
  position: relative;
  transition: all 200ms linear;
}

  /* #Primary style
================================================== */

  /*  */
  .nav-item .dropdown-menu {
      transform: translate3d(0, 10px, 0);
      visibility: hidden;
      opacity: 0;
      max-height: 0;
      display: block;
      padding: 0;
      margin: 0;
      transition: all 200ms linear;
  }

  .nav-item.show .dropdown-menu {
      opacity: 1;
      visibility: visible;
      max-height: 999px;
      transform: translate3d(0, 0px, 0);
  }

.dropdown-menu {
  padding: 10px !important;
  margin: 0;
  font-size: 13px;
  letter-spacing: 1px;
  color: #000;
  background-color: #fcfaff;
  border: none;
  border-radius: 3px;
  box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
  transition: all 200ms linear;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-item {
  padding: 3px 15px;
  color: #212121;
  border-radius: 2px;
  transition: all 200ms linear;
}

  .dropdown-item:hover,
  .dropdown-item:focus {
      color: #fff;
      background-color: #000;
  }

body.dark {
  color: #fff;
  background-color: #1f2029;
}

  body.dark .navbar-brand img {
      filter: brightness(100%);
  }

  body.dark .nav-link {
      color: #fff !important;
  }

  body.dark .nav-item.active .nav-link {
      color: #999 !important;
  }

  body.dark .dropdown-menu {
      color: #fff;
      background-color: #1f2029;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
  }

  body.dark .dropdown-item {
      color: #fff;
  }

  body.dark .navbar-light .navbar-togler-icon {
      border-bottom: 1px solid #fff;
  }

      body.dark .navbar-light .navbar-togler-icon:after,
      body.dark .navbar-light .navbar-togler-icon:before {
          background-color: #fff;
      }

  body.dark .navbar-togler[aria-expanded="true"] .navbar-togler-icon {
      border-color: transparent;
  }


.nav-item:hover .custom-dropdown {
  display: block;
}


/* #Media
================================================== */

@media (max-width: 767px) {
  .navbar-togler {
      display: block;
  }
  .nav-experience-card {
      display: flex;
      flex-direction: column;
      /*justify-content:space-around;*/
      background-color: #e5e4e6;
      height: 110px;
      align-items:normal;
  }

      .nav-experience-card h6 {
          margin-top:10px;
          font-size: 13px;
          font-weight: 700;
          color: #1ea1bc;
          line-height: 5px;
          align-items: center;
          text-align: center;
      }

  .header-contact-details-card {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin-top: 15px;
  }

  .header-contact-detail {
      justify-content: center;
      align-items: center;
      line-height: 15px;
      
  }
  .det-li {
      padding: 10px 20px;
      border-radius: 25px;
      color: #fff;
      background: #004658;
      text-decoration: none;
  }
      .det-li:hover {
          color: #fff;
          background-color: #c87e4f;
      }
      .det-li a:hover {
          color: #fff;
      }
      .det-li .contect-person-name {
          display: none;
      }
      .det-li a {
          font-size: 13px;
          color: #fff;
          text-decoration: none;
      }

  .mobile-icon{
      display:none;
  }

  .book-me {
      display: flex;
      justify-content: center;
      align-items: center;
  }

      .book-me a {
          /*display: inline-block;*/
          padding: 10px 20px;
          border-radius: 25px;
          color: #fff;
          background: #004658;
          font-size:13px;
          text-decoration: none;
          /*margin-top: 25px;*/
          transition: all 0.3s ease;
      }

          .book-me a:hover {
              color: #fff;
              background-color: #c87e4f;
          }
  .nav-item:after {
      display: none;
  }

  .nav-link {
      margin-left: 20px;
  }

  .nav-item::before {
      position: absolute;
      display: block;
      top: 15px;
      left: 0;
      width: 11px;
      height: 1px;
      content: "";
      border: none;
      background-color: #000;
      vertical-align: 0;
  }

  .dropdown-toggle[aria-expanded="true"]::after {
      transform: rotate(90deg);
      opacity: 0;
  }

  .dropdown-menu {
      padding: 0 !important;
      background-color: #fff;
      box-shadow: none;
      transition: all 200ms linear;
  }

  .dropdown-toggle[aria-expanded="true"] + .dropdown-menu {
      margin-top: 10px !important;
      margin-bottom: 20px !important;
  }

  body.dark .nav-item::before {
      background-color: #fff;
  }

  body.dark .dropdown-toggle::after {
      background-color: #fff;
  }

  body.dark .dropdown-menu {
      background-color: transparent;
      box-shadow: none;
  }
}

/* #Link to page
================================================== */

.logo {
  position: absolute;
  bottom: 30px;
  right: 30px;
  display: block;
  z-index: 100;
  transition: all 250ms linear;
}

  .logo img {
      height: 26px;
      width: auto;
      display: block;
      filter: brightness(10%);
      transition: all 250ms linear;
  }

body.dark .logo img {
  filter: brightness(100%);
}
/*===================================================================*/
.main {
  height: 70vh;
  width: 100%;
}

.wrapper,
.slide {
  position: relative;
  width: 100%;
  height: 100%;
}

.slide {
  overflow: hidden;
}

  .slide::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      z-index: 10;
  }

  .slide .image {
      height: 100%;
      width: 100%;
      object-fit: cover;
  }

  .slide .image-data {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      width: 100%;
      z-index: 100;
  }

.image-data span.text {
  font-size: 22px;
  font-weight: 400;
  color: #fff;
}

.image-data h2 {
  font-size: 45px;
  font-weight: 600;
  color: #fff;
}

a.button {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 25px;
  color: #333;
  background: #fff;
  text-decoration: none;
  margin-top: 25px;
  transition: all 0.3s ease;
}

  a.button:hover {
      color: #fff;
      background-color: #c87e4f;
  }

/* swiper button css */
.nav-btn {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3);
}

  .nav-btn:hover {
      background: rgba(255, 255, 255, 0.4);
  }

.swiper-button-next {
  right: 50px;
}

.swiper-button-prev {
  left: 50px;
}

.nav-btn::before,
.nav-btn::after {
  font-size: 25px;
  color: #fff;
}

.swiper-pagination-bullet {
  opacity: 1;
  height: 12px;
  width: 12px;
  background-color: #fff;
  visibility: hidden;
}

.swiper-pagination-bullet-active {
  border: 2px solid #fff;
  background-color: #c87e4f;
}

@media screen and (max-width: 768px) {
  .nav-btn {
      visibility: hidden;
  }

  .swiper-pagination-bullet {
      visibility: visible;
  }
}

/* =============================================================================== */
.boxes-headline {
  text-align: center;
  font-weight: 700;
  margin-top: 30px;
}

.services-section h1 {
  font-size: 41px;
  font-weight: 700;
  text-align: center;
  margin: 40px 0 40px 0;
}

.services-section p {
  text-align: center;
  font-weight: 400;
  font-size: 22px;
  letter-spacing: 1px;
  margin: 0 100px;
}

.services-box-card {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.services-box {
  padding: 25px 70px;
  background-color: rgb(232, 232, 232);
  color: #000000;
  margin: 50px;
  box-shadow: 5px 5px 5px #333;
  border: 2px solid #fff;
  border-radius: 20px;
  justify-content: center;
  text-align: center;
}

  .services-box i {
      color: cadetblue;
      font-size: 40px;
  }

  .services-box h3 {
      font-size: 20px;
      font-weight: 700;
  }

@media only screen and (max-width: 650px) {
  .services-section p {
      text-align: center;
      font-weight: 400;
      font-size: 20px;
      letter-spacing: 1px;
      margin: 0 10px;
  }

  .services-box-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
  }

  .services-box {
      padding: 25px 70px;
      background-color: rgb(232, 232, 232);
      color: #000000;
      margin: 20px;
      box-shadow: 5px 5px 5px #333;
      border: 2px solid #fff;
      border-radius: 20px;
      justify-content: center;
      text-align: center;
  }
}


/* ==========================================Card css======================================================= */
.cards-body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #f6f5f5;
  padding-bottom: 50px;
  /* background: linear-gradient(215deg,#1ea1bc,#13469f,#020024); */
  /* background: linear-gradient(24deg, #1ea1bc, #020024); */
}

.container-cards {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  align-items: center;
  justify-content: center;
  padding: 40px;
  margin: 0;
}

  .container-cards .card {
      position: relative;
      width: 320px;
      height: 350px;
      background: #fff;
      border-radius: 20px;
      overflow: hidden;
      transform: translateY(10px);
      box-shadow: 2px 2px 20px #333;
  }

      .container-cards .card:hover {
          background: #000;
          transform: scale(1.1);
          transition-delay: 0.1s;
      }

      .container-cards .card:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(215deg,#1ea1bc,#020024);
          
          clip-path: circle(180px at 30% 80%);
          transition: 0.5s ease-in-out;
      }

      .container-cards .card:hover:before {
          clip-path: circle(290px at 50% 40%);
          /* background: linear-gradient(312deg,#1ea1bc, #000 50%, #1ea1bc 20%, #020024); */
      }

      .container-cards .card:after {
          content: '';
          position: absolute;
          top: 30%;
          left: -20%;
          font-size: 12em;
          font-weight: 800;
          font-style: italic;
          color: rgba(255,255,25,0.05)
      }

      .container-cards .card .imgBx {
          position: absolute;
          top: 20%;
          transform: translateY(-50%);
          z-index: 10000;
          width: 10%;
          /* height: 220px; */
          left: 40%;
          margin-top: -30px;
          transition: 0.3s;
      }

      .container-cards .card:hover .imgBx {
          top: 80%;
          transform: translateY(0%);
      }

      .container-cards .card .imgBx i {
          font-size: 35px;
          /* border: 1px solid #fff;
  border-radius: 50px; */
          /* background-color: #ffffff; */
          padding: 4px;
          color: #000;
          position: relative;
          top: 25%;
          left: 10%;
          transform: translate(-50%, -50%) rotate(180deg);
          width: 170px;
      }

      .container-cards .card:hover .imgBx i {
          /* background-color: #000; */
          color: #1ea1bc;
      }

      .container-cards .card .contentBx {
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 70px;
          /* text-align: center; */
          left: 10%;
          transition: 1s;
          z-index: 10;
      }

      .container-cards .card:hover .contentBx {
          height: 320px;
          text-align: center;
          left: 0%;
      }

      .container-cards .card .contentBx h2 {
          position: relative;
          font-weight: 400;
          letter-spacing: 0px;
          color: #fff;
          margin: 0;
      }

      .container-cards .card .contentBx .size, .container-cards .card .contentBx .color {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px 20px;
          transition: 0.5s;
          opacity: 0;
          visibility: hidden;
          padding-top: 10px;
          padding-bottom: 0;
      }

      .container-cards .card:hover .contentBx .size {
          opacity: 1;
          visibility: visible;
          transition-delay: 0.5s;
      }

      .container-cards .card:hover .contentBx .color {
          opacity: 1;
          visibility: visible;
          transition-delay: 0.6s;
      }

      .container-cards .card .contentBx .size p, .container-cards .card .contentBx .color h3 {
          color: #fff;
          font-weight: 300;
          font-size: 14px;
          margin-left: -10px;
      }

      /* .container-cards .card .contentBx .size span{
width: 26px;
height: 26px;
text-align: center;
line-height: 26px;
font-size: 14px;
display: inline-block;
color: #111;
background: #fff;
margin: 0 5px;
transition: 0.5s;
color: #111;
border-radius: 4px;
cursor: pointer;
}

.container-cards .card .contentBx .size span:hover{
background: #9bdc28;
}

.container-cards .card .contentBx .color span{
width: 20px;
height: 20px;
background: #ff0;
border-radius: 50%;
margin: 0 5px;
cursor: pointer;
}

.container-cards .card .contentBx .color span:nth-child(2){
background: #9bdc28;
}

.container-cards .card .contentBx .color span:nth-child(3){
background: #03a9f4;
}

.container-cards .card .contentBx .color span:nth-child(4){
background: #e91e63;
} */

      .container-cards .card .contentBx a {
          display: inline-block;
          /* padding: 10px 20px; */
          /* background: #fff; */
          /* border-radius: 4px; */
          margin-top: 10px;
          text-decoration: none;
          font-weight: 600;
          color: #fff;
          opacity: 0;
          transform: translateY(50px);
          transition: 0.5s;
          margin-top: 0;
      }

      .container-cards .card:hover .contentBx a {
          opacity: 1;
          transform: translateY(0px);
          transition-delay: 0.45s;
          margin-left: 0px;
          margin-top: 10px;
      }

.ri-arrow-right-line {
  position: absolute;
  margin-top: 0px;
}
/* ===========================================contact us================================================= */
/* .footer {

} */
.contact-us-svg {
  background-color: transparent;
  display: block;
  height: 50px;
  position: relative;
  top: -1px;
  width: 100%;
}

.contact-us-path {
  fill: #f6f5f5;
}

.Contact-Us-Section {
  /* padding-top:40px; */
  background-color: #000;
  color: #fff;
}

.contact-us-Svg svg {
  display: block;
  height: auto;
  width: 100%;
}

.contact-us-card {
  background-color: #000;
  color: #fff;
  padding: 100px 100px 100px 100px;
  text-align: center;
}

  .contact-us-card p {
      margin: 0px 200px;
  }

.contact-numbers {
  display: flex;
  justify-content: center;
  margin: 20px 0px;
}

  .contact-numbers button {
      padding: 5px 12px;
      font-weight: 700;
      font-size: 25px;
      border-radius: 20px;
  }
/* =====================================Testimonial===================================================== */


figure.Testimonial-card-details {
  font-family: 'Raleway', Arial, sans-serif;
  position: relative;
  overflow: hidden;
  margin: 50px 10px 10px 10px;
  min-width: 220px;
  max-width: 310px;
  width: 100%;
  color: #fff;
  text-align: left;
  box-shadow: none !important;
}

  figure.Testimonial-card-details * {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-transition: all 0.35s cubic-bezier(0.25, 0.5, 0.5, 0.9);
      transition: all 0.35s cubic-bezier(0.25, 0.5, 0.5, 0.9);
  }

  figure.Testimonial-card-details img {
      max-width: 100%;
      vertical-align: middle;
      height: 90px;
      width: 90px;
      border-radius: 50%;
      margin: 40px 0 0 10px;
  }

  figure.Testimonial-card-details blockquote {
      display: block;
      border-radius: 8px;
      position: relative;
      background-color: #1ea1bc;
      padding: 25px 50px 30px 50px;
      font-size: 0.8em;
      font-weight: 500;
      margin: 0;
      line-height: 1.6em;
      font-style: italic;
  }

      figure.Testimonial-card-details blockquote:before,
      figure.Testimonial-card-details blockquote:after {
          font-family: 'FontAwesome';
          content: "\201C";
          position: absolute;
          font-size: 50px;
          opacity: 0.3;
          font-style: normal;
      }

      figure.Testimonial-card-details blockquote:before {
          top: 25px;
          left: 20px;
      }

      figure.Testimonial-card-details blockquote:after {
          content: "\201D";
          right: 20px;
          bottom: 0;
      }

  figure.Testimonial-card-details .arrow {
      top: 100%;
      width: 0;
      height: 0;
      border-left: 0 solid transparent;
      border-right: 25px solid transparent;
      border-top: 25px solid #1ea1bc;
      margin: 0;
      position: absolute;
  }

  figure.Testimonial-card-details .author {
      position: absolute;
      bottom: 45px;
      padding: 0 10px 0 120px;
      margin: 0;
      text-transform: uppercase;
      color: #000;
      -webkit-transform: translateY(50%);
      transform: translateY(50%);
  }

      figure.Testimonial-card-details .author h5 {
          opacity: 0.8;
          margin: 0;
          font-weight: 800;
      }

          figure.Testimonial-card-details .author h5 span {
              font-weight: 400;
              text-transform: none;
              padding-left: 5px;
          }


/* Demo purposes only */
/* html {
height: 100%;
} */
.Testimonial-card {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: wrap;
  margin: 0;
  height: 100%;
}

/* ===============================================footer======================================================== */

/* a {

} */
.social-Icon-design {
  color: #fff;
  text-decoration: none;
}

.pg-footer {
  font-family: 'Roboto', sans-serif;
}


.footer {
  background-color: #000;
  color: #fff;
}

.footer-wave-svg {
  background-color: transparent;
  display: block;
  height: 30px;
  position: relative;
  top: -1px;
  width: 100%;
}

.footer-wave-path {
  fill: #fff;
}

.footer-content {
  margin-left: auto;
  margin-right: auto;
  max-width: 1230px;
  padding: 40px 15px 450px;
  position: relative;
}

.footer-content-column {
  box-sizing: border-box;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  color: #fff;
}

  .footer-content-column ul li a {
      color: #fff;
      text-decoration: none;
  }

.footer-logo-link {
  display: inline-block;
}

.footer-menu {
  margin-top: 30px;
}

.footer-menu-name {
  color: #ffff;
  font-size: 15px;
  font-weight: 900;
  letter-spacing: .1em;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 0;
  text-transform: uppercase;
}

.footer-menu-list {
  list-style: none;
  margin-bottom: 0;
  margin-top: 10px;
  padding-left: 0;
}

  .footer-menu-list li {
      margin-top: 5px;
  }

.footer-call-to-action-description {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 20px;
}

.footer-call-to-action-button:hover {
  background-color: #fff;
  color: #00bef0;
}

.button:last-of-type {
  margin-right: 0;
}

.footer-call-to-action-button {
  background-color: #027b9a;
  border-radius: 21px;
  color: #fff;
  display: inline-block;
  font-size: 11px;
  font-weight: 900;
  letter-spacing: .1em;
  line-height: 18px;
  padding: 12px 30px;
  margin: 0 10px 10px 0;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color .2s;
  cursor: pointer;
  position: relative;
}

.footer-call-to-action {
  margin-top: 30px;
}

.footer-call-to-action-title {
  color: #fffff2;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: .1em;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 0;
  text-transform: uppercase;
}

.footer-call-to-action-link-wrapper {
  margin-bottom: 0;
  margin-top: 10px;
  color: #fff;
  text-decoration: none;
}

  .footer-call-to-action-link-wrapper a {
      color: #fff;
      text-decoration: none;
  }





.footer-social-links {
  bottom: 0;
  height: 54px;
  position: absolute;
  right: 50px;
  width: 236px;
}

.footer-social-amoeba-svg {
  height: 54px;
  left: 0;
  display: block;
  position: absolute;
  top: 0;
  width: 236px;
}

.footer-social-amoeba-path {
  fill: #00D639;
}

.footer-social-link.linkedin {
  font-size: 25px;
  left: 2px;
  top: 11px;
  /* width: 46px; */
}

.footer-social-link {
  display: block;
  padding: 10px;
  position: absolute;
}

.hidden-link-text {
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px,1px,1px,1px);
  --webkit-clip-path: inset(0px 0px 99.9% 99.9%);
  clip-path: inset(0px 0px 99.9% 99.9%);
  overflow: hidden;
  height: 1px;
  width: 1px;
  padding: 0;
  border: 0;
  top: 50%;
}

.footer-social-icon-svg {
  display: block;
}

.footer-social-icon-path {
  fill: #fffff2;
  transition: fill .2s;
}

.footer-social-link.twitter {
  font-size: 25px;
  left: 62px;
  top: 3px;
}

.footer-social-link.youtube {
  font-size: 25px;
  left: 123px;
  top: 12px;
}

.footer-social-link.github {
  font-size: 25px;
  left: 172px;
  top: 7px;
}

.footer-copyright {
  background-color: #00D639;
  color: #fff;
  padding: 15px 30px;
  text-align: center;
}

.footer-copyright-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
}

.footer-copyright-text {
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 0;
}

.footer-copyright-link {
  color: #fff;
  text-decoration: none;
}







/* Media Query For different screens */
@media (min-width:320px) and (max-width:479px) { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  .contact-us-card {
      background-color: #000;
      color: #fff;
      padding: 20px 10px 10px 10px;
      text-align: center;
  }

      .contact-us-card p {
          margin: 0px 10px;
      }

  .footer-content {
      margin-left: auto;
      margin-right: auto;
      max-width: 1230px;
      padding: 40px 15px 1050px;
      position: relative;
  }
}

@media (min-width:480px) and (max-width:599px) { /* smartphones, Android phones, landscape iPhone */
  .contact-us-card {
      background-color: #000;
      color: #fff;
      padding: 20px 10px 10px 10px;
      text-align: center;
  }

      .contact-us-card p {
          margin: 0px 10px;
      }

  .footer-content {
      margin-left: auto;
      margin-right: auto;
      max-width: 1230px;
      padding: 40px 15px 1050px;
      position: relative;
  }
}

@media (min-width:600px) and (max-width: 800px) { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  .contact-us-card {
      background-color: #000;
      color: #fff;
      padding: 20px 10px 10px 10px;
      text-align: center;
  }

      .contact-us-card p {
          margin: 0px 10px;
      }

  .footer-content {
      margin-left: auto;
      margin-right: auto;
      max-width: 1230px;
      padding: 40px 15px 1050px;
      position: relative;
  }
}

@media (min-width:801px) { /* tablet, landscape iPad, lo-res laptops ands desktops */

}

@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */

}

@media (min-width:1281px) { /* hi-res laptops and desktops */

}




@media (min-width: 760px) {
  /* .contact-us-card{
  background-color: #000;
  color: #fff;
  padding: 20px 10px 10px 10px;
  text-align: center;
}
.contact-us-card p{
  margin:0px 10px;
} */
  .footer-content {
      margin-left: auto;
      margin-right: auto;
      max-width: 1230px;
      padding: 40px 15px 450px;
      position: relative;
  }

  .footer-wave-svg {
      height: 50px;
  }

  .footer-content-column {
      width: 24.99%;
  }
}

@media (min-width: 568px) {
  /* .footer-content-column {
    width: 49.99%;
} */
}
/*======================================Gallary====================================*//**/

.collection {
  padding: 2em;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-template-rows: 100px;
  grid-gap: .5em;
  grid-auto-rows: 100px;
  grid-auto-flow: dense;
}

@media (max-width: 785px) {
  .collection {
      padding: .5em;
      grid-gap: .25em;
  }
}

.collection .item {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

  .collection .item:nth-of-type(3n) {
      grid-column: span 2;
  }

@media (max-width: 400px) {
  .collection .item:nth-of-type(3n) {
      grid-column: span 1;
  }
}

.collection .item:nth-of-type(4n) {
  grid-row: span 2;
}

.collection .item img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

  .collection .item img:hover {
      cursor: pointer;
  }

.modal {
  display: none;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-content {
  margin: auto;
  display: block;
  max-width: 80%;
  position: relative;
  padding: 20px;
  background-color: #fefefe;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  color: #999;
}

  .modal-close:hover {
      color: #333;
  }

.modal-content img {
  max-width: 100%;
  height: auto;
}