.list-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.list-column {
    flex: 1 0 45%; /* Adjust this value as needed for spacing */
    margin: 10px; /* Adjust spacing between columns */
}

ul {
    padding: 0;
    margin: 0;
}

li {
    list-style: none;
    margin-bottom: 10px; /* Adjust spacing between items */
}

.dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #00D639;
    margin-right: 5px;
}

.blog-card {
    clip-path: polygon(100% 0, 100% 75%, 50% 100%, 0 75%, 0 0, 0% 0%);
    background-color: black;
    padding: 100px 0 50px 0;
}

    .blog-card h1 {
        text-align: center;
        font-weight: 900;
        font-size: 55px;
        color: #fff;
    }

@media only screen and (max-width: 650px) {
    .blog-card {
        clip-path: polygon(100% 0, 100% 85%, 50% 100%, 0 85%, 0 0, 0% 0%);
        padding: 50px 0 50px 0;
    }

        .blog-card h1 {
            font-size: 45px;
        }
}
/* =====================================junk-removal-info-section===================================== */
.junk-removal-info-section {
    display: flex;
    flex-direction: row;
    padding: 40px 0;
}

.free-junk-info-div {
    width: 100%;
    padding-right: 20px;
}

.junk-removal-info-section h1 {
    font-family: sage headline, helvetica neue, Roboto, Segoe UI, Arial;
    font-size: 55px;
    font-weight: 900;
}

.junk-removal-info-section p {
    font-family: sage headline, helvetica neue, Roboto, Segoe UI, Arial;
    font-size: 22px;
    font-weight: 400;
}
/* .junk-boxes-card-content-img{
    width: 50%;
} */
.junk-removal-info-img img {
    width: 100%;
    height: 100%;
}

.junk-removing-img {
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    position: sticky;
    background-size: cover;
}

@media only screen and (max-width: 650px) {
    /* =====================================junk-removal-info-section===================================== */
    .junk-removal-info-section {
        display: flex;
        flex-direction: column;
        padding: 20px 20px;
    }

    .free-junk-info-div {
        width: 100%;
        padding-right: 0px;
    }

    .junk-removal-info-section h1 {
        font-family: sage headline, helvetica neue, Roboto, Segoe UI, Arial;
        font-size: 45px;
        font-weight: 900;
    }

    .junk-removal-info-section p {
        font-family: sage headline, helvetica neue, Roboto, Segoe UI, Arial;
        font-size: 20px;
        font-weight: 400;
    }

    .junk-removal-info-img img {
        width: 100%;
        height: 100%;
    }

    .junk-removing-img {
        border-top-left-radius: 40px;
        border-bottom-left-radius: 40px;
        border-top-right-radius: 40px;
        border-bottom-right-radius: 40px;
        position: sticky;
        background-size: cover;
    }

    .call-us-section {
        background: #fff;
        padding: 20px;
        margin-top: 30px;
    }

    .contect-num {
        text-align: center;
        font-size: 45px;
        font-weight: 800;
        color: #000;
    }
}

.blog-paragraph {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    padding: 50px;
}

/* ========================================================================================== */
.contect-num {
    text-align: center;
    font-size: 45px;
    font-weight: 800;
    color: #000;
}

.call-us-section {
    background: #fff;
    padding: 70px;
    margin-top: 30px;
}

.number-design {
    color: #00D639;
}

.Service-design {
    text-align: center;
    color: #000;
    font-size: 20px;
}

.number {
    text-decoration: none;
    color: #00D639;
    cursor: pointer;
}

.number-btn {
    text-decoration: none;
    color: #00D639;
    cursor: pointer;
}

    .number-btn i {
        margin-right: 3px;
    }

    .number-btn:hover {
        color: #fff;
    }

@media only screen and (max-width: 650px) {
    .junk-removal-info-section h1 {
        font-family: sage headline, helvetica neue, Roboto, Segoe UI, Arial;
        font-size: 36px;
        font-weight: 900;
    }

    .call-us-section {
        background: #fff;
        padding: 20px;
        margin-top: 30px;
    }

    .contect-num {
        text-align: center;
        font-size: 36px;
        font-weight: 800;
        color: #000;
    }
}
