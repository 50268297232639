/* MyNavbar.css */
/* .navigation-wrap {
    background: #000;
    position: relative;
    width: 100%;
    top: 0px;
    left: 0;
    z-index: 1000;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    padding: 20px 50px; 
  } */
  .navbar {
    padding: 10px 40px; 
    border-radius: 20px;
    background-color: #fff;
  }
  .navbar-nav .nav-link {
    color: white !important; /* Default link color */
    transition: color 0.3s, border-bottom-width 0.3s, padding-bottom 0.3s; /* Transition for color, underline thickness, and padding */
    border-bottom: 3px solid transparent; /* Initially hide the underline */
    padding-bottom: 5px; /* Add padding below the links */
  }
  
  .navbar-nav .nav-link.active {
    color: #00D639 !important; /* Active link color */
    border-bottom-color: #00D639; /* Underline color for active link */
  }
  
  .navbar-nav .nav-link:hover {
    color: #00D639 !important; /* Link color on hover */
    border-bottom-color: #00D639; /* Underline color on hover */
  }
  
  .navbar-design{
    margin-left: 100px;
  }

  @media (min-width:190px) and (max-width:779px) {
    .navbar-design{
      margin-left: 0px;
      padding-top: 20px;
    }
  }
