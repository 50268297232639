/* Carousel.css */
.carousel-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
}

.carousel-content h3 {
    font-size: 24px;
    margin-bottom: 10px;
}

.carousel-content p {
    font-size: 16px;
    margin-bottom: 20px;
}

.carousel-content a {
    background-color: green;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
}
.slide::before {
    display: none !important; /* This will hide the background overlay */
}
.carousel-image {
    max-width: 100%; /* Ensure images don't exceed the container width */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover;
    opacity: 5;
    max-height: 400px; /* Set maximum height for images */
}

.carousel .legend a  {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 25px;
    color: #333;
    background: #fff;
    text-decoration: none;
    margin-top: 25px;
    transition: all 0.3s ease;
}

.carousel .legend a:hover {
        color: #fff;
        background-color: #c87e4f;
    }
.carousel .slide .legend {
    transition: all .5s ease-in-out;
    position: absolute;
    bottom: 40px;
    left: 50%;
    top: 25%;
    margin-left: -45%;
    width: 90%;
    border-radius: 10px;
    background: none;
    color: #fff;
    padding: 10px;
    font-size: 12px;
    text-align: center;
    opacity: 0.25;
    transition: opacity .35s ease-in-out;
}
/* .carousel .slide .legend h2{
    font-size: 45px;
font-weight: 600;
color: #fff;
} */
.carousel .legend .button {
    background-color: #fff;
    color: #000;
    padding: 10px 20px;
    text-decoration: none;
    display: inline-block;
    margin-top: 10px;
    border-radius: 5px;
    transition: none; /* Remove hover transition */
}
@media (min-width: 1200px) {
    .h2, h2 {
        font-size: 45px;
        font-weight: 600;
        color: #fff;
    }
    .legend p{
        font-size: 22px;
    }
}
.carousel .slide {
    position: relative;
    height: 400px; /* Set the height of the slide */
    background-color: #000;
}

.carousel .slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.7; /* Adjust the opacity to make the image darker */
}

.carousel:hover .slide img {
    opacity: 0.7; /* Restore full opacity on hover */
}
