.contact-us-card {
    clip-path: polygon(100% 0, 100% 75%, 50% 100%, 0 75%, 0 0, 0% 0%);
    background-color: black;
    padding: 100px 0 50px 0;
}

    .contact-us-card h1 {
        text-align: center;
        font-weight: 900;
        font-size: 55px;
        color: #fff;
    }
/*======================================Gallary====================================*/ /**/

.collection {
    padding: 2em;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-template-rows: 100px;
    grid-gap: .5em;
    grid-auto-rows: 100px;
    grid-auto-flow: dense;
}

@media (max-width: 785px) {
    .collection {
        padding: .5em;
        grid-gap: .25em;
    }
}

.collection .item {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

    .collection .item:nth-of-type(3n) {
        grid-column: span 2;
    }

@media (max-width: 400px) {
    .collection .item:nth-of-type(3n) {
        grid-column: span 1;
    }
}

.collection .item:nth-of-type(4n) {
    grid-row: span 2;
}

.collection .item img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
}

    .collection .item img:hover {
        cursor: pointer;
    }

.modal {
    display: none;
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.7);
}

.modal-content {
    margin: auto;
    display: block;
    max-width: 80%;
    position: relative;
    padding: 20px;
    background-color: #fefefe;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    color: #999;
}

    .modal-close:hover {
        color: #333;
    }

.modal-content img {
    max-width: 100%;
    height: auto;
}
.contect-num {
    margin:20px 0;
    text-align: center;
    font-size: 45px;
    font-weight: 800;
    color: #000;
}
.number-design {
    color: #00D639;
}
@media only screen and (max-width: 650px) {

    .contect-num {
        text-align: center;
        font-size: 36px;
        font-weight: 800;
        color: #000;
    }
}

/* Media Query For different screens */
@media (min-width:220px) and (max-width:479px) { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
    .contact-us-card {
        padding: 50px 0 50px 0;
    }

        .contact-us-card h1 {
            font-size: 35px;
        }
}

@media (min-width:480px) and (max-width:599px) { /* smartphones, Android phones, landscape iPhone */
    .contact-us-card {
        padding: 50px 0 50px 0;
    }

        .contact-us-card h1 {
            font-size: 35px;
        }
}

@media (min-width:600px) and (max-width: 800px) { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    .contact-us-card {
        padding: 50px 0 50px 0;
    }

        .contact-us-card h1 {
            font-size: 35px;
        }

}